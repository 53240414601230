import imgAutomatic from '@assets/images/img-automatic.png'
import imgAdministrative from '@assets/images/img-save-time-administrative.png'
import imgTsqPlanning from '@assets/images/img-tailor-intelligent-planning.png'
import imgPlanning from '@assets/images/img-uptodate-planning.png'

const content = [
    {
        title: 'Planning intelligent',
        description: {
            text: "Grâce à l'intelligence artificielle et au machine learning, Staffea vous suggère automatiquement des horaires et des roulements en fonction des besoins de votre établissement, dans le but d'optimiser vos plannings. Les préférences des collaborateurs sont prises en compte pour améliorer leur qualité de vie au travail en Ehpad !",
            highlight: ['vous suggère automatiquement des horaires et des roulements'],
        },
        image: imgTsqPlanning,
        alt: "Staffea, application web écran d'automatisation des plannings",
    },
    {
        title: 'Gain de temps administratif',
        description: {
            text: "Avec Staffea, votre planning se dématérialise pour gagner en efficacité et travailler sereinement. Les plannings, contrats de travail, arrêts maladies, congés, demandes d'absences et demandes d'échanges d'horaires entre salariés sont intégrés et mis à jour instantanément sur l'application.",
            highlight: ['votre planning se dématérialise'],
        },
        image: imgAdministrative,
        alt: 'Staffea, application web écran des demandes de congés',
    },
    {
        title: 'Planning à jour',
        description: {
            text: 'Toutes les heures réellement effectuées sont comptabilisées au fur et à mesure dans le logiciel de gestion des temps de travail, grâce au système de pointage. Cela simplifie le calcul des éléments variables de paie et évite toute erreur.',
            highlight: ['simplifie le calcul des éléments variables de paie'],
        },
        image: imgPlanning,
        alt: 'Staffea, application web écran de gestion du planning des managers',
    },
    {
        title: 'Maintenance automatique',
        description: {
            text: "Les mises à jour du logiciel d'organisation de planning sont incluses dans votre abonnement. Toutes vos données sont automatiquement sauvegardées. C'est simple, vous n'avez aucune maintenance logiciel ou serveur à faire !",
            highlight: ["Les mises à jour du logiciel d'organisation de planning sont incluses"],
        },
        image: imgAutomatic,
        alt: "Staffea, application web écran d'accueil avec notifications de mise à jour",
    },
]

export default content
